import styled from 'styled-components';
import { Container } from '../../globalStyles';

export const Title = styled.h1`
  text-align: center;
  text-decoration: underline;
  font-weight: 100;
  font-size: 55px;
  padding-top: 2%;
  color: #0c3c60;
`;

export const FlexItem = styled.div`
  margin: 20px;
  width: 300px;
`;

export const FlexImg = styled.img`
  width: 230px;
  margin: 20px;
`;

export const FlexR = styled.div`
  display: flex;
  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const FlexC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1% 0;
`;

export const Header = styled.h3`
  color: #0c3c60;
`;

export const Container2 = styled(Container)`
  @media screen and (min-width: 1080px) {
    height: 90vh;
  }
`;
