import React from 'react';
import { InfoSection } from '../../components';
import { Container } from '../../globalStyles';
import { Header, Body } from './Cancellation.elements';

const Cancellation = () => {
  return (
    <>
      <InfoSection id="cancellation" lightBg="39729b">
        <Container style={{ height: '90vh' }}>
          <Header>Cancellation and Missed Appointment Policy</Header>
          <Body>
            If you are unable to keep a scheduled appointment, please give 24 hours advanced notice to ensure that you
            will not be charged for the appointment.
            <br />
            <br />
            If less then 24 hours notice is given and we are unable to fill your time slot, you will be expected to pay
            for the appointment.
          </Body>
        </Container>
      </InfoSection>
    </>
  );
};

export default Cancellation;
