import React from 'react';
import { InfoSection } from '../../components';
import { FlexImg, FlexItem, Title, FlexC, FlexR, Header, Container2 } from './Pricing.elements';
import pricing1 from './pricing1.bmp';
import pricing2 from './pricing2.bmp';
import pricing3 from './pricing3.bmp';
import pricing4 from './pricing4.bmp';
import pricing5 from './pricing5.bmp';
import pricing6 from './pricing6.bmp';

const Pricing = () => {
  return (
    <>
      <InfoSection id="pricing">
        <Container2>
          <Title>PRICING</Title>
          <FlexC>
            <FlexR>
              <FlexImg src={pricing1} />
              <FlexItem>
                <Header>Massage Therapy</Header>
                <br />
                <p>$50 per 30 minutes</p>
                <br />
                <p>$85 per 60 minutes</p>
                <br />
                <p>$125 per 90 minutes</p>
              </FlexItem>
              <FlexImg src={pricing2} />
            </FlexR>
            <FlexR>
              <FlexImg src={pricing3} />
              <FlexItem>
                <Header>Rock Tape</Header>
                <p>Please call or email for information. Price depends on what is in need of being taped.</p>
              </FlexItem>
              <FlexImg src={pricing4} />
            </FlexR>
            <FlexR>
              <FlexImg src={pricing5} />
              <FlexItem>
                <Header style={{ textAlign: 'center' }}>Gift Certificates Available</Header>
              </FlexItem>
              <FlexImg src={pricing6} />
            </FlexR>
          </FlexC>
        </Container2>
      </InfoSection>
    </>
  );
};

export default Pricing;
