import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import {
  NavBackground,
  NavbarContainer,
  NavbarContainerTwo,
  NavLogo,
  NavMenu,
  NavItem,
  NavLinks,
  MobileIcon,
} from './Navbar.elements';

const Navbar = () => {
  const [click, setClick] = useState(false);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const handleClick = () => setClick(!click);
  return (
    <>
      <NavBackground>
        <NavbarContainer>
          <NavbarContainerTwo style={{ padding: '0 5%' }}>
            <NavLogo to="/#home" scroll={scrollWithOffset}>
              KIRSTIN CARD
              <br />
              FIT MASSAGE
            </NavLogo>
            <MobileIcon onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to="/#home" scroll={scrollWithOffset}>
                  HOME
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/#aboutMe" scroll={scrollWithOffset}>
                  ABOUT ME
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/#services" scroll={scrollWithOffset}>
                  SERVICES
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/#getInTouch" scroll={scrollWithOffset}>
                  GET IN TOUCH
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/pricing/#pricing" scroll={scrollWithOffset}>
                  PRICING
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/cancellation-policy/#cancellation" scroll={scrollWithOffset}>
                  CANCELLATION
                </NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainerTwo>
        </NavbarContainer>
      </NavBackground>
    </>
  );
};

export default Navbar;
