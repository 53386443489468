import React from 'react';
import { InfoSec } from './InfoSection.elements';
import { Container } from '../../globalStyles';

const InfoSection = ({ id, lightBg, imgBg, children }) => {
  return (
    <>
      <InfoSec id={id} lightBg={lightBg} imgBg={imgBg}>
        <Container>{children}</Container>
      </InfoSec>
    </>
  );
};

export default InfoSection;
