import styled from 'styled-components';

export const Header = styled.p`
  text-align: center;
  align-items: center;
  color: #fff;
  background-color: #6f98b6;
  font-size: 55px;
  line-height: 1.3em;
  width: 33%;
  margin: auto auto;

  @media screen and (max-width: 1080px) {
    width: 90%;
    font-size: 35px;
  }
`;

export const Body = styled.p`
  padding: 3%;
  text-align: center;
  color: #fff;
  font-size: 30px;
  width: 33%;
  margin: auto auto;

  @media screen and (max-width: 1080px) {
    width: 90%;
    font-size: 20px;
  }
`;
