import styled from 'styled-components';

export const Centered = styled.div`
  text-align: center;
  padding: 1% 5%;
`;

export const Title = styled.p`
  font-size: ${({ size }) => (size ? size : '74')}px;
  text-shadow: rgb(0 0 0 / 40%) 0px 4px 5px;
  color: #${({ color }) => (color ? color : 'fff')};
  font-weight: 100;

  @media screen and (max-width: 1080px) {
    font-size: ${({ size }) => (size ? size / 2 : '37')}px;
  }
`;

export const Spacing = styled.div`
  padding: ${({ padding }) => padding}px;

  @media screen and (max-width: 1080px) {
    padding: ${({ padding }) => padding / 2}px;
  }
`;

export const Name = styled.h2`
  font-weight: 100;
  letter-spacing: 0.1em;
  width: 20rem;
  padding: 6% 0;

  @media screen and (max-width: 1080px) {
    width: 10rem;
  }
`;

export const Name2 = styled.h2`
  font-weight: 100;
  letter-spacing: 0.1em;
  padding: 10px 0;

  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`;

export const Profile = styled.img`
  border-radius: 50%;
  max-width: 180px;
  margin-bottom: 15px;
`;

export const FlexRow = styled.div`
  display: flex;
  padding: 5% 20%;
  flex-basis: 50%;
  flex-grow: 1;
  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  flex-grow: 1;
`;

export const DarkText = styled.p`
  color: #0c3c60;
  font-weight: bold;
  letter-spacing: 0.05em;
  font-size: 15px;
  line-height: 1.7em;
`;

export const PadLeft = styled.ul`
  padding-left: 20px;
  color: #0c3c60;
`;

export const FlexR = styled.div`
  display: flex;
  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
`;
export const FlexC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;
`;

export const BorderTop = styled.div`
  padding: 5%;
  width: 500px;
  text-align: center;
  border-top: 2px solid lightgray;

  @media screen and (max-width: 1080px) {
    width: 100%;
    border: 0;
  }
`;

export const BorderRight = styled.div`
  padding: 5%;
  width: 500px;
  text-align: center;
  border-right: 2px solid lightgray;

  @media screen and (max-width: 1080px) {
    width: 100%;
    border: 0;
  }
`;

export const BorderBot = styled.div`
  padding: 5%;
  width: 500px;
  transform: translateY(2px);
  text-align: center;
  border-bottom: 2px solid lightgray;

  @media screen and (max-width: 1080px) {
    border: 0;
    width: 100%;
  }
`;

export const BorderLeft = styled.div`
  padding: 5%;
  width: 500px;
  transform: translateX(-2px);
  text-align: center;
  border-left: 2px solid lightgray;

  @media screen and (max-width: 1080px) {
    border: 0;
    width: 100%;
  }
`;

export const Map = styled.iframe`
  width: 1000px;
  height: 600px;
  border: 0;
  margin: 20px;

  @media screen and (max-width: 1080px) {
    border: 0;
    width: 100%;
    margin: 5px;
  }
`;

export const Text = styled.p`
  line-height: 1.8em;
  letter-spacing: 0.02em;
`;

export const EmailIcon = styled.div`
  font-size: 1.8rem;
`;
