import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import massageImg from '../../massage.bmp';
import massageImg2 from '../../massage2.png';
import profileImg from '../../profile.bmp';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { InfoSection } from '../../components';
import {
  Centered,
  Title,
  Spacing,
  Profile,
  Name,
  Name2,
  FlexRow,
  FlexColumn,
  DarkText,
  PadLeft,
  BorderBot,
  BorderRight,
  BorderLeft,
  BorderTop,
  FlexC,
  FlexR,
  Map,
  Text,
  EmailIcon,
} from './Home.elements';

const HomePage = () => {
  return (
    <>
      <InfoSection id="home" imgBg={massageImg}>
        <Home />
      </InfoSection>
      <InfoSection id="aboutMe">
        <AboutMe />
      </InfoSection>
      <InfoSection imgBg={massageImg2}>
        <Spacing padding="340" />
      </InfoSection>
      <InfoSection id="services">
        <Services />
      </InfoSection>
      <InfoSection id="getInTouch" lightBg="1b99e8">
        <GetInTouch />
      </InfoSection>
      <InfoSection lightBg="b3c0d3">
        <MapMe />
      </InfoSection>
    </>
  );
};

export default HomePage;

const Home = () => {
  return (
    <>
      <Centered>
        <Title color="313e4f" style={{ paddingTop: '40px' }}>
          FIT MASSAGE
        </Title>
        <Title size="59" style={{ padding: '100px 0' }}>
          HELPING YOU STAY
          <br />
          FIT FOR WORK. FIT FOR PLAY.
          <br />
          FIT FOR LIFE.
        </Title>
      </Centered>
    </>
  );
};

const AboutMe = () => {
  return (
    <>
      <FlexRow>
        <FlexColumn>
          <p>About me</p>
          <Name>Kirstin Card, LMT, CPT</Name>
          <Profile src={profileImg} />
        </FlexColumn>
        <FlexColumn>
          <p>
            I am a licensed massage therapist and certified personal trainer located in Gresham, Oregon.
            <br /> With a background in teaching group fitness and a desire to help others, I completed the Massage
            Therapy program at the University of Western States.
          </p>
          <u style={{ paddingTop: '10px' }}>
            <DarkText>My Specialties are:</DarkText>
          </u>
          <PadLeft>
            <li>
              <DarkText>Sports Massage</DarkText>
            </li>
            <li>
              <DarkText>Gua Sha</DarkText>
            </li>
            <li>
              <DarkText>Deep Tissue</DarkText>
            </li>
            <li>
              <DarkText>RockTape</DarkText>
            </li>
          </PadLeft>
          <p style={{ paddingTop: '10px' }}>
            I love helping my clients feel their very best. Whether you have old or new injuries, depression, or need
            help de-stressing from what life brings, I am here to assist you in meeting your health goals.
          </p>
          <em style={{ padding: '30px 0' }}>
            "Massage is not just a luxury. <br />
            It's a way to a healthier, happier life."
          </em>
        </FlexColumn>
      </FlexRow>
    </>
  );
};

const Services = () => {
  return (
    <>
      <FlexC>
        <FlexR>
          <BorderRight>
            <Name2>Sports Massage</Name2>
            <Text>
              Sports massage is a form of bodywork geared toward participants in athletics. It is used to help prevent
              injuries, prepare the body for athletic activity and maintain it in optimal condition, and help athletes
              recover from workouts and injuries.
            </Text>
          </BorderRight>
          <BorderBot>
            <Name2>Gua sha</Name2>
            <Text>
              Gua sha is a natural, alternative therapy that involves scraping the skin with a massage tool to improve
              circulation. This ancient Chinese healing technique may offer a unique approach to better health,
              addressing issues like chronic pain.
            </Text>
          </BorderBot>
        </FlexR>
        <FlexR>
          <BorderTop>
            <Name2>Deep Tissue</Name2>
            <Text>
              Deep Tissue massage uses slower, more-forceful strokes to target the deeper layers of muscle and
              connective tissue, commonly used to help with muscle damage from injuries.
            </Text>
          </BorderTop>
          <BorderLeft>
            <Name2>RockTape</Name2>
            <Text>
              RockTape is a form of kinesiology tape that microscopically lifts the skin away from muscle and fascia
              below, creating a decompressive effect. It can be used to treat sports and non-sports injuries including
              shin splints, plantar fasciitis, runner's knee and back pain.
            </Text>
          </BorderLeft>
        </FlexR>
      </FlexC>
    </>
  );
};

const GetInTouch = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('FitMassage', 'template_nr4608c', form.current, 'J1D4-6Z4HRXsySqaR').then(
      (result) => {
        console.log(result.text);
        document.getElementById('emailSuccess').style.display = 'block';
        clearForm();
        setTimeout(clearEmailmsg, 5000);
      },
      (error) => {
        console.log(error.text);
        document.getElementById('emailFail').style.display = 'block';
        setTimeout(clearEmailmsg, 5000);
      }
    );
  };

  const clearEmailmsg = () => {
    document.getElementById('emailSuccess').style.display = 'none';
    document.getElementById('emailFail').style.display = 'none';
  };

  const clearForm = () => {
    document.getElementById('user_name').value = '';
    document.getElementById('user_email').value = '';
    document.getElementById('subject').value = '';
    document.getElementById('message').value = '';
  };

  return (
    <>
      <Container style={{ padding: '20px' }}>
        <FlexR>
          <FlexC style={{ flexGrow: 1 }}>
            <span style={{ color: '#fff', lineHeight: '1.3em', fontSize: '55px' }}>GET IN TOUCH</span>
            <FlexR>
              <FlexC>
                <p style={{ color: '#fff', fontSize: '19px' }}>Tel: 503 - 660 - 8338</p>
              </FlexC>
              <FlexC>
                <u style={{ color: '#fff', fontSize: '19px' }}>Hours:</u>
                <p style={{ color: '#fff', fontSize: '19px' }}>
                  <br />
                  Mon. Thur. 9am-6pm
                  <br />
                  Wed. Fri. 9am-5pm
                </p>
              </FlexC>
            </FlexR>
            <div>
              <p style={{ color: '#fff', fontSize: '19px', letterSpacing: '0.05em', lineHeight: '1.7em' }}>
                417 E. Powell Blvd.
                <br />
                Gresham, OR 97030
                <br />
                <a href="mailto:fitmassagegresham@gmail.com" style={{ color: '#fff', fontSize: '19px' }}>
                  fitmassagegresham@gmail.com
                </a>
                <br />
                (Located inside Johnson Chiropractic)
              </p>
            </div>
          </FlexC>
          <FlexR>
            <form ref={form} onSubmit={sendEmail}>
              <FlexR>
                <TextField
                  required
                  id="user_name"
                  name="user_name"
                  label="Name"
                  variant="standard"
                  sx={{
                    m: 2,
                    color: '#fff',
                    '& .MuiFormLabel-root': {
                      color: '#fff',
                    },
                    '& .MuiInputBase-root': {
                      color: '#fff',
                    },
                  }}
                />
                <TextField
                  required
                  id="user_email"
                  name="user_email"
                  label="Email"
                  type="email"
                  variant="standard"
                  sx={{
                    m: 2,
                    color: '#fff',
                    '& .MuiFormLabel-root': {
                      color: '#fff',
                    },
                    '& .MuiInputBase-root': {
                      color: '#fff',
                    },
                  }}
                />
              </FlexR>
              <TextField
                fullWidth
                id="subject"
                name="subject"
                label="Subject"
                variant="standard"
                sx={{
                  m: 2,
                  color: '#fff',
                  '& .MuiFormLabel-root': {
                    color: '#fff',
                  },
                  '& .MuiInputBase-root': {
                    color: '#fff',
                  },
                }}
              />
              <TextField
                fullWidth
                multiline
                id="message"
                name="message"
                label="Message"
                variant="standard"
                rows={4}
                sx={{
                  m: 2,
                  color: '#fff',
                  '& .MuiFormLabel-root': {
                    color: '#fff',
                  },
                  '& .MuiInputBase-root': {
                    color: '#fff',
                  },
                }}
              />
              <Button type="submit" variant="text" sx={{ color: '#fff' }}>
                Send
              </Button>
              <EmailIcon id="emailSuccess" style={{ display: 'none' }}>
                <FcCheckmark />
                <span style={{ color: '#fff', fontSize: '1.8rem', margin: 'auto' }}>Success!</span>
              </EmailIcon>
              <EmailIcon id="emailFail" style={{ display: 'none' }}>
                <FcCancel />
                <span style={{ color: '#fff', fontSize: '1.8rem', margin: 'auto' }}>Error, try again later</span>
              </EmailIcon>
            </form>
          </FlexR>
        </FlexR>
      </Container>
    </>
  );
};

const MapMe = () => {
  let initMap = () => {};
  return (
    <>
      <Container style={{ textAlign: 'center' }}>
        <Map
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.5852224389246!2d-122.43103066648136!3d45.49829667235223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549598f4483c71c1%3A0xfd62801824cd2517!2sFit%20Massage!5e0!3m2!1sen!2sus!4v1667242097559!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></Map>
      </Container>
    </>
  );
};
