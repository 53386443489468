import React from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home/Home';
import Pricing from './pages/Pricing/Pricing';
import Cancellation from './pages/Cancellation/Cancellation';
import { Navbar } from './components';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Navbar></Navbar>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/pricing" exact element={<Pricing />} />
        <Route path="/cancellation-policy" exact element={<Cancellation />} />
      </Routes>
    </Router>
  );
}

export default App;
