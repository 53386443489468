import styled from 'styled-components';
import { Container } from '../../globalStyles';
import { HashLink } from 'react-router-hash-link';

export const NavBackground = styled.nav`
  background: #84d3e3;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 90px;
  border-top: 3px solid #4986a1;
  border-bottom: 3px solid #4986a1;
  padding-left: 0px;
  padding-right: 0px;
  ${Container}
`;

export const NavbarContainerTwo = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 70px;
  border-top: 1px solid #4986a1;
  border-bottom: 1px solid #4986a1;
  margin-top: 7px;
  ${Container}
`;

export const NavLogo = styled(HashLink)`
  color: #4986a1;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.2em;
  font-size: 16px;
  display: flex;
  align-items: center;
  background: #a6deee;
  opacity: 0.7;
  height: 100px;
  padding: 0 15px 0 15px;
  transform: translateY(-16px);
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 100px;
    left: ${({ click }) => (click ? 0 : '-100%')};
    opacity: 1;
    transition: all 0.5s ease;
    background: #84d3e3;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1080px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 100%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavItem = styled.li`
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #4986a1;
  }

  @media screen and (max-width: 1080px) {
    width: 100%;
    &:hover {
      border: none;
    }
  }
`;

export const NavLinks = styled(HashLink)`
  color: #4986a1;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  @media screen and (max-width: 1080px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #fff;
      transition: all 0.3s ease;
    }
  }
`;
