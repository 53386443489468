import styled from 'styled-components';

export const InfoSec = styled.div`
  color: #fff;
  ${({ imgBg }) =>
    imgBg
      ? `background-image: url(${imgBg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      `
      : 'background: #daedff;'};
  ${({ lightBg }) => (lightBg ? `background-color: #${lightBg};` : 'background-color: #daedff;')};
  padding-top: 20px;
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 1080px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 1080px) {
    padding-bottom: 65px;
  }
`;
